<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-row>
                  <div class="col-md-12" id="form">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.hand_note_info')}}</legend>
                      <!-- <b-overlay :show="loading"> -->
                        <b-table-simple borderless hover small caption-top>
                          <tbody>
                            <tr>
                              <th style="width: 18%"  class="text-left" >{{$t('elearning_config.fiscal_year')}}</th>
                              <th class="text-center" style="width: 2%">:</th>
                              <td style="width: 80%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                            </tr>
                            <tr>
                              <th style="width: 18%" class="text-left">{{ $t('elearning_config.training_title') }}</th>
                              <th style="width: 2%" class="text-center" >:</th>
                              <td style="width: 80%" class="text-left" >{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                            </tr>
                            <tr>
                              <th style="width: 18%" class="text-left">{{ $t('elearning_iabm.circular_memo_no') }}</th>
                              <th style="width: 2%" class="text-center" >:</th>
                              <td style="width: 80%" class="text-left" >{{ formData.circular_memo_no }}</td>
                            </tr>
                            <tr>
                              <th style="width: 18%"  class="text-left" >{{$t('elearning_iabm.batch_no')}}</th>
                              <th class="text-center" style="width: 2%">:</th>
                              <td style="width: 80%" class="text-left">{{ $i18n.locale === 'bn' ? formData.batch_bn : formData.batch }}</td>
                            </tr>
                            <tr>
                              <th style="width: 18%"  class="text-left" >{{$t('elearning_config.speaker_facilitator')}}</th>
                              <th class="text-center" style="width: 2%">:</th>
                              <td style="width: 80%" class="text-left">{{ $i18n.locale === 'bn' ? formData.speaker_bn : formData.speaker }}</td>
                            </tr>
                            <tr>
                              <th style="width: 18%"  class="text-left" >{{$t('elearning_tpm.course_name')}}</th>
                              <th class="text-center" style="width: 2%">:</th>
                              <td style="width: 80%" class="text-left">{{ $i18n.locale === 'bn' ? formData.topics_session_bn : formData.topics_session }}</td>
                            </tr>
                          </tbody>
                        </b-table-simple>
                      <!-- </b-overlay> -->
                    </fieldset>
                  </div>
                </b-row>
                <b-row>
                  <div class="col-md-12" id="form">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.upload_info')}}</legend>
                      <b-overlay :show="loading">
                        <b-table-simple bordered>
                          <b-tr>
                            <b-th class="text-center" style="width:15%">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th class="text-center" style="width:45%">{{ $t('externalTraining.note_title') }}</b-th>
                            <b-th class="text-center" style="width:40%">{{ $t('globalTrans.attachment') }}</b-th>
                          </b-tr>
                          <b-tr v-for="(item,index) in formData.note_details" :key="index">
                            <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                            <b-td class="text-center">{{ $i18n.locale === 'bn' ? item.note_title_bn : item.note_title }}</b-td>
                            <b-td class="text-center">
                              <a v-if="item.attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/' + item.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                            </b-td>
                          </b-tr>
                        </b-table-simple>
                      </b-overlay>
                    </fieldset>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <!-- <pre>{{allData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { handNoteShow } from '../../api/routes'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getHandNoteData(tmp.course_id, tmp.trainer_id, tmp.circular_memo_no)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      loading: false,
      datas: [],
      allData: {
        fiscal_year_id: 0,
        trainer_evaluation_marks: []
      },
      dates: [],
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
  },
  methods: {
    async getHandNoteData (courseId, trainerId, circularMemoNo) {
      this.loading = true
      const params = {
        course_id: courseId,
        trainer_id: trainerId,
        circular_memo_no: circularMemoNo
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, handNoteShow, params)
      if (!result.success) {
        this.formData.note_details = []
      } else {
        this.formData.note_details = result.data.note_details
        this.loading = false
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
