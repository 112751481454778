<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-overlay :show="loading">
              <fieldset class="p-2 w-100">
                <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.hand_note_info')}}</legend>
                <b-col>
                  <b-table-simple borderless hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 18%"  class="text-left" >{{$t('elearning_config.fiscal_year')}}</th>
                        <th class="text-center" style="width: 2%">:</th>
                        <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                        <th style="width: 18%" class="text-left">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 2%" class="text-center" >:</th>
                        <td style="width: 30%" class="text-left" >{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                      </tr>
                      <tr>
                        <th style="width: 18%" class="text-left">{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th style="width: 2%" class="text-center" >:</th>
                        <td style="width: 30%" class="text-left" >{{ formData.circular_memo_no }}</td>
                        <th style="width: 18%"  class="text-left" >{{$t('elearning_iabm.batch_no')}}</th>
                        <th class="text-center" style="width: 2%">:</th>
                        <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.batch_bn : formData.batch }}</td>
                      </tr>
                      <tr>
                        <th style="width: 18%" class="text-left">{{ $t('elearning_config.speaker_facilitator') }}</th>
                        <th style="width: 2%" class="text-center" >:</th>
                        <td style="width: 30%" class="text-left" >{{ $i18n.locale === 'bn' ? formData.speaker_bn : formData.speaker }}</td>
                        <th style="width: 18%"  class="text-left" >{{$t('elearning_tpm.course_name')}}</th>
                        <th class="text-center" style="width: 2%">:</th>
                        <td colspan="4" style="width: 80%" class="text-left">{{ $i18n.locale === 'bn' ? formData.topics_session_bn : formData.topics_session }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
              </fieldset>
              <br/>
              <fieldset class="p-2 w-100">
                <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.upload_info')}}</legend>
                <b-row>
                  <div class="col-12 pl-4 pr-4">
                    <table class="table" style="width:100%" border="1">
                      <thead class="thead">
                        <tr>
                          <th style="width:9%">{{$t('globalTrans.sl_no')}} <span class="text-danger">*</span></th>
                          <th style="width:26%">{{$t('externalTraining.note_title')}} {{$t('globalTrans.enn')}} <span class="text-danger">*</span></th>
                          <th style="width:26%">{{$t('externalTraining.note_title')}} {{$t('globalTrans.bnn')}} <span class="text-danger">*</span></th>
                          <th style="width:31%">{{$t('globalTrans.attachment')}} <span class="text-danger">*</span></th>
                          <th style="width:8%" class="text-center">{{$t('globalTrans.action')}}</th>
                        </tr>
                      </thead>
                      <tr v-for="(note_data, index) in formData.note_details" :key="index">
                        <td>{{ $n(index + 1) }}</td>
                        <td>
                          <ValidationProvider :vid="'note_title' + index" rules="required"  name="Note Title (En)">
                            <div slot-scope="{ valid, errors }">
                              <b-form-input
                                v-model="note_data.note_title"
                                :name="'note_details['+index+'][note_title]'"
                                :id="'note_details['+index+'][note_title]'"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="error invalid-feedback" role="alert">
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </td>
                        <td>
                          <ValidationProvider :vid="'note_title_bn' + index"  rules="required"  name="Note Title (Bn)">
                            <div slot-scope="{ valid, errors }">
                              <b-form-input
                                v-model="note_data.note_title_bn"
                                :name="'note_details['+index+'][note_title_bn]'"
                                :id="'note_details['+index+'][note_title_bn]'"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="error invalid-feedback" role="alert">
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </td>
                        <td>
                          <input type="hidden" :name="'note_details['+index+'][id]'" v-model="note_data.id"/>
                          <input type="hidden" :name="'note_details['+index+'][pre_attachment]'" v-model="note_data.attachment"/>
                          <ValidationProvider :vid="'note_details' + '.' + index + '.' + 'attachment'"  rules="required"  name="Attachment">
                            <div slot-scope="{ valid, errors }">
                              <b-form-file
                                v-model="note_data.attachment"
                                :name="'note_details['+index+'][attachment]'"
                                :id="'note_details['+index+'][attachment]'"
                                accept=".pdf,.doc,.docx"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <div class="error invalid-feedback d-block" role="alert">
                                {{ errors[0] }}
                              </div>
                              <a v-if="note_data.id" target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/' + note_data.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                              <p>{{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is DOC/DOCX/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট DOC/DOCX/PDF]' }}</p>
                            </div>
                          </ValidationProvider>
                        </td>
                        <td class="text-center">
                          <button @click="removeAttachment(index, note_data)" class="btn btn-sm btn-danger float-right" type="button">
                            <i class="fas fa-window-close m-0"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="6">
                          <button
                            @click="addMore"
                            class="btn btn-sm btn-primary float-right"
                            type="button"
                          ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </b-row>
              </fieldset>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                </div>
              </div>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { handNoteStore, handNoteShow } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getForm()
      this.formData.fiscal_year = tmp.fiscal_year
      this.formData.fiscal_year_bn = tmp.fiscal_year_bn
      this.formData.circular_memo_no = tmp.circular_memo_no
      this.formData.batch_no = tmp.batch_no
      this.formData.training_title = tmp.training_title
      this.formData.training_title_bn = tmp.training_title_bn
      this.formData.course_id = tmp.course_id
      this.formData.trainer_id = tmp.trainer_id
      this.formData.topics_session_bn = tmp.topics_session_bn
      this.formData.topics_session = tmp.topics_session
      this.formData.batch = tmp.batch
      this.formData.batch_bn = tmp.batch_bn
      this.formData.speaker = tmp.speaker
      this.formData.speaker_bn = tmp.speaker_bn
      this.getFormData(tmp.course_id, tmp.trainer_id, tmp.circular_memo_no)
    }
    // if (this.$route.query.id) {
    // }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      circularLoading: false,
      saveBtnName: this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: '',
        course_id: 0,
        batch_no: 0,
        status: 1,
        trainer_id: 0,
        note_details: [
          {
            id: '',
            note_title: '',
            note_title_bn: '',
            attachment: null,
            pre_attachment: []
          }
        ]
      },
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      note_details_remove: [],
      trainerListData: [],
      trainerErrors: [],
      allBatchListData: [],
      trainingCategoryList: [],
      trainingTitleList: [],
      batchList: [],
      circularList: [],
      evaluationMarks: [],
      dateList: [],
      trainerList: []
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.traineeTranerObj.trainerEvaluationList
    },
    gradingStatus: function () {
      return this.$store.state.TrainingElearning.commonObj.traineeTranerObj.gradingStatus
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    officeTypeList: function () {
      return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
    },
    officeList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0).map(obj => {
              if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
              } else {
              return { value: obj.value, text: obj.text_en }
              }
          })
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  methods: {
    addMore () {
      this.formData.note_details.push({
        id: '',
        note_title: '',
        note_title_bn: '',
        attachment: null,
        pre_attachment: []
      })
    },
    removeAttachment (index, data) {
      this.formData.note_details.splice(index, 1)
      this.note_details_remove.push(data)
    },
    getForm () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    gradingStatusSet (data) {
      const gradeList = this.gradingStatus.find(gradingStatus => gradingStatus.value === data.grading_status_id)
      data.attain_marks = gradeList.average_number
      return data
    },
    async getFormData (courseId, trainerId, circularMemoNo) {
      this.loading = true
      const params = {
        course_id: courseId,
        trainer_id: trainerId,
        circular_memo_no: circularMemoNo
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, handNoteShow, params)
      if (!result.success) {
        this.formData = {
          id: '',
          circular_memo_no: this.formData.circular_memo_no,
          fiscal_year: this.formData.fiscal_year,
          fiscal_year_bn: this.formData.fiscal_year_bn,
          batch_no: this.formData.batch_no,
          training_title: this.formData.training_title,
          training_title_bn: this.formData.training_title_bn,
          course_id: this.formData.course_id,
          trainer_id: this.formData.trainer_id,
          topics_session_bn: this.formData.topics_session_bn,
          topics_session: this.formData.topics_session,
          batch: this.formData.batch,
          batch_bn: this.formData.batch_bn,
          speaker: this.formData.speaker,
          speaker_bn: this.formData.speaker_bn,
          note_details: [
            {
              id: '',
              note_title: '',
              note_title_bn: '',
              attachment: null,
              pre_attachment: []
            }
          ]
        }
      } else {
        this.formData = {
          id: result.data.id,
          circular_memo_no: this.formData.circular_memo_no,
          fiscal_year: this.formData.fiscal_year,
          fiscal_year_bn: this.formData.fiscal_year_bn,
          batch_no: this.formData.batch_no,
          training_title: this.formData.training_title,
          training_title_bn: this.formData.training_title_bn,
          course_id: this.formData.course_id,
          trainer_id: this.formData.trainer_id,
          topics_session_bn: this.formData.topics_session_bn,
          topics_session: this.formData.topics_session,
          batch: this.formData.batch,
          batch_bn: this.formData.batch_bn,
          speaker: this.formData.speaker,
          speaker_bn: this.formData.speaker_bn,
          note_details: result.data.note_details
        }
      }
      this.loading = false
    },
    async createData () {
      this.loading = true
      const loadinState = { loading: false, listReload: false }
      let result = null
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData(document.getElementById('form'))
      Object.keys(this.formData).map(key => {
        if (key !== 'note_details') {
          formData.append(key, this.formData[key])
        }
      })
      formData.append('note_details_remove', JSON.stringify(this.note_details_remove))
      // if (this.formData.id) {
        formData.append('_method', 'POST')
      //   result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainerEvaluationUpdate}/${this.formData.id}`, formData, config)
      // } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, handNoteStore, formData, config)
      // }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        if (result.errors) {
          this.trainerErrors = result.errors
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
  }
  }
}
</script>
